<template>
  <ik-data-table
    ref="table"
    :entity-name="$t('repertory')"
    :model="model"
    icon="mdi-label"
    style="width: 100%"
    @reloaded="reloaded"
  />
</template>

<script>
  import { IkDataTable } from 'metaflow-js'
  import DCacheProperty from '../../../model/restaurant/DCacheProperty'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: DCacheProperty,
      })
    },
    methods: {
      reloaded () {
      },
    },
  }
</script>

<style scoped>

</style>
