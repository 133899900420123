import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import Category from '@/model/dish/Category'
import i18n from '../../i18n'

const entity = {
  id: {},
  code: {},
  dishName: {
    displayName: 'name',
  },
  dishesCategoryId: {
    displayName: 'category', // 分类
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Category.getList,
        itemText: '_langsname',
      },
    },
  },

  threshold: {},
  shortFactor: {},

}

const config = {
  edit: async function (item) {
    item.ids = item.id
    return (await hillo.post('DCache.php?op=setDCachePropertyForIdIn',
      item,
    )).content
  },

  load: async function (filter) {
    return ((await hillo.get('DCache.php?op=showDCacheProperty',
      { lang: i18n.locale },
    )).content)
  },
}

export default IKDataEntity.ModelFactory(entity, config)
